<template>
  <Doughnut
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="id"
    dataset-id-key="label"
    :width="300"
    :height="300"
  />
</template>

<script>
import { Doughnut } from 'vue-chartjs/legacy';

import { Chart as ChartJS, Tooltip, ArcElement, CategoryScale } from 'chart.js';

ChartJS.register(Tooltip, ArcElement, CategoryScale);

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    labels: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    backgroundColors: {
      type: Array,
      required: true,
    },
  },
  components: { Doughnut },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
          },
        ],
      },
      chartOptions: {
        cutout: 50,
        borderWidth: 0,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            enabled: true,
            intersect: false,
            mode: 'nearest',
            bodySpacing: 5,
            yPadding: 10,
            xPadding: 10,
            caretPadding: 0,
            displayColors: false,
            backgroundColor: '#333',
            titleFontColor: '#ffffff',
            cornerRadius: 4,
            footerSpacing: 0,
            titleSpacing: 0,
            callbacks: {
              label: (context) => {
                return `${context.label}: ${parseInt(context.formattedValue)}`;
              },
            },
          },
        },
      },
    };
  },
  watch: {
    data: {
      handler() {
        this.update();
      },
      deep: true,
    },
  },
  mounted() {
    this.update();
  },
  methods: {
    update() {
      this.chartData.labels = this.labels;
      this.chartData.datasets[0].backgroundColor = this.backgroundColors;
      this.chartData.datasets[0].data = this.data;
    },
  },
};
</script>
